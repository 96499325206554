$dark-color: #333335;
$light-color: #f0f6ff;

#menuToggleMobile{
  display: none;
}
#menuToggleMobile input {
  height: 30px;
  width: 40px;
  position: absolute;
  right: 20px;
  top: 20px;

  cursor: pointer;

  opacity: 0;
  z-index: 2;

  -webkit-touch-callout: none;
}
#menuToggleMobile span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: $dark-color;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: all 0.2s ease;
}
#menu {
  position: absolute;
  z-index: 10;
  top: 50px;
  left: 0;
  right: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 2px solid $dark-color;

  background: $light-color;
  list-style-type: none;

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: all 0.2s ease;
}
#menuToggleMobile input:checked~ul {
  transform: none;
  opacity: 1;
}
#menuToggleMobile span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggleMobile input:checked ~ span:nth-child(2) {
  opacity: 1;
  -webkit-transform: rotate(43deg);
  -moz-transform: rotate(43deg);
  -o-transform: rotate(43deg);
  transform: rotate(43deg);
}
#menuToggleMobile input:checked ~ span:nth-child(4)
{
  opacity: 1;
  -webkit-transform: rotate(-43deg);
  -moz-transform: rotate(-43deg);
  -o-transform: rotate(-43deg);
  transform: rotate(-43deg);
}
#menuToggleMobile input:checked ~ span:nth-child(3)
{
  opacity: 0;
  width: 0;
}
.nav-btn-mobile-text{
  margin: 5px 10px;
  padding-left: 25px;
  border-left: 2px solid $dark-color;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}
.nav-btn-mobile-loggedIn{
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
.nav-btn-mobile-user{
  display: flex;
  align-items: center;
}
.nav-btn-user-image{
  width: 20px;
  height: 100%;
  object-fit: cover;
  border-radius: 1em;
  margin-right: 5px;
}
.nav-header {
  padding: 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  position: sticky;
  top: 0;
  z-index: 99;
  background: $light-color;
  -webkit-box-shadow: 0px 0px 25px 0px $light-color;
  -moz-box-shadow: 0px 0px 25px 0px $light-color;
  box-shadow: 0px 0px 25px 0px $light-color;
}

.nav-btn:focus {
  border: none;
  outline: none;
  border: 1px solid white;
}

.nav-btn:active{
  border: 1px solid white;
  -webkit-box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.1),
  inset -5px -5px 5px rgba(255, 255, 255, 0.9);
  -moz-box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.1),
  inset -5px -5px 5px rgba(255, 255, 255, 0.9);
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.1),
  inset -5px -5px 5px rgba(255, 255, 255, 0.9);
}

.logo {
  flex-grow: 1;
}
.nav-search-mobile{
  flex-grow: 2;
}
.nav-right{
  display: flex;
  align-items: center;
}
.nav-btn{
  border: 1px solid white;
}
.nav-user-btn{
  // border: 1px solid white;
  position: relative;
  border-radius: 1em;
}
.nav-user-img{
  border-radius: 1em;
  height: 100%;
  width: 32px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
}
.nav-user-text{
  align-items: center;
  padding-left: 16px;
}
.nav-user-account{
  display: none;
  position: absolute;
  top: 32px;
  right: 0;
  left: 10px;
  text-align: center;
  border: 1px solid white;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
}
.nav-user-account-style-account{
  cursor: pointer;
  padding: 7px 10px;
  background: $light-color;
  color: $dark-color;
}
.nav-user-account-style-logout{
  cursor: pointer;
  padding: 7px 10px;
  background: $dark-color;
  color: $light-color;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
}
.fa-plus, .fa-minus{
  font-size: 15px;
}
// Screen
@media all and (max-width: 1100px) and (min-width: 700px){
  .nav-header{
    flex-wrap: wrap;
  }
  .nav-search-box{
    order: 3;
    padding: 0;
  }
  .nav-search-mobile{
    order: 3;
    padding: 0 50px;
    margin-top: 20px;
  }
}
@media all and (max-width: 800px){
  #menuToggleMobile{
    display: block;
  }
  #menuToggleDesktop{
    display: none;
  }
  .nav-search-mobile {
    margin-top: 10px;
  }
}
@media all and (max-width: 700px){
  .nav-header{
    flex-wrap: wrap;
  }
  .nav-search-box{
    order: 2;
  }
  .nav-search-mobile {
    order: 3;
    padding: 0 75px;
  }
  .nav-btn-mobile li {
    font-size: 14px;
  }
}
@media all and (max-width: 500px){
  .nav-search-mobile {
    padding: 0;
  }
}