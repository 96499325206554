$dark-color: #333335;
$light-color: #f0f6ff;

.confirmation-page{
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
  border-radius: 1em;
}
.confirmation-page-map{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}
.confirmation-page-location{
  background: black;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  height: 300px;
  position: relative;
}
.confirmation-page-place{
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  background: $light-color;
  width: 90%;
  max-width: 500px;
  height: 100px;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  padding: 20px;
}
.confirmation-page-body{
  padding: 20px;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}
.confirmation-page-categories{
  overflow-x: auto;
  padding: 10px;
}
.confirmation-page-category{
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 1em;
  border: solid 1px black;
  white-space: nowrap;
}
.confirmation-page-website{
  display: flex;
  justify-content: space-between;
  margin: 25px 10px;
}
.confirmation-page-website-name{
  white-space: nowrap;
  padding: 10px;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  color: $light-color;
  border: 1px solid #eb0a0a;
  background: radial-gradient(100% 156.45% at 100% 100%, rgba(233, 130, 12, 0.929) 0%, rgba(255, 0, 0, 0) 100%), #eb0a0a;
}
.confirmation-page-website-edit{
  background: white;
  width: 100%;
  // border-radius: 1em; 
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  border: 1px solid white;
  padding-left: 15px;
}
.confirmation-page-website-edit:focus{
  border: 1px solid #eb0a0a;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  outline: none;
}
.confirmation-page-website-invalid{
  text-align: center;
  padding-bottom: 25px;
  color: red;
  display: none;
}
.confirmation-page-images{
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 25px;
  border: 1px dashed black;
  border-radius: 1em;
  height: 300px;
  width: 100%;
  background: white;

  display: flex;
}
.no-border{
  border: none;
}
.confirmation-page-image{
  object-fit: contain;
  height: 100%;
  
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}
.confirmation-page-image-overlay {
  margin-right: 5px;
  background: black;
  height: 100%;
}
.confirmation-page-image:hover {
  background: black;
  opacity: .5;

  cursor: pointer;
}
.confirmation-page-edit-images{
  text-align: center;
}
.confirmation-page-add-image{
  margin-top: 25px;
}
.confirmation-page-add-image-btn{
  color: $light-color;
  background: #126486;
  border-radius: 1em;
  padding: 10px 20px;

  cursor: pointer;
}
.confirmation-page-suggestion{
  margin-top: 50px;
  position: relative;
}
.confirmation-page-suggestion-title{
  
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  background: $light-color;
  z-index: 4;
  width: 100px;
}
.confirmation-page-hr{
  z-index: -1;
  position: absolute;
  width: 90%;
  top: 10px;

  background-image: linear-gradient(to right, $dark-color, rgba(0, 0, 0, 0));
}
.confirmation-page-suggestion-box{
  padding: 25px;
}
.confirmation-page-suggestion-textarea{
  resize: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 1em;
  padding: 10px;
  border: 1px solid white;
  height: 300px;
}
.confirmation-page-suggestion-textarea:focus {
  outline: none;
  border: 1px solid #126486;

  transition: border .2s ease-out;
}

.confirmation-page-delete-warning{
  text-align: center;
  background: rgba(255, 0, 0, .75);
  border-radius: 1em;
  margin: 50px auto 0 auto;
  padding: 25px;
  width: 90%;
}

.confirmation-page-btns{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.confirmation-page-cancel-btn{
  margin-right: 10px;
}
.confirmation-page-save-btn{
  background: $dark-color;
  color: $light-color;
  margin-left: 10px;
}
.confirmation-page-loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}

@media all and (max-width: 700px) {
  .confirmation-page-body {
    padding: 10px;
  }
}