.public-profile{
  margin-top: 25px;
  text-align: center;
}
.public-profile-body{
  display: flex;
}

.public-profile-header{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
}
.public-profile-image{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}
.public-profile-name-box{
  width: 100%;
  max-width: 500px;
}
.public-profile-name{
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
  padding: 20px 0;
  margin: 0 25px;
}
.public-profile-follow-btn{
  padding: 7px 20px;
  border-radius: 1em;
  border: solid 1px #ff0497;
  color: white;
  margin: auto 0 auto auto;
  background: radial-gradient(100% 156.45% at 100% 100%, #ff0497 0%, rgba(255, 0, 0, 0) 100%), rgba(221, 33, 121, 0.929);
  transition: background .2s ease-out;
}
.public-profile-follow-btn:hover{
  color: #ff0497;
  background: white;
}
.public-profile-subheader{
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
}
.public-profile-mobile{
  display: none;
}

@media all and (max-width: 1100px) {
  .public-profile-body{
    flex-direction: column;
  }
  .public-profile-mobile{
    display: flex;
  }
  .public-profile-name{
    padding: 25px 0;
  }
}

@media all and (max-width: 700px) {
  .public-profile-header{
    margin: 10px;
  }
  .public-profile-image{
    height: 100px;
    width: 100px;
  }
  .public-profile-name {
    padding: 10px 0;
    margin: 0;
    font-size: 26px;
  }
  .public-profile-subheader{
    margin: 10px 0;
  }
  .public-profile-follow-btn{
    padding: 7px 10px;
  }
}