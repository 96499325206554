$dark-color: #333335;
$light-color: #f0f6ff;

.place-header{
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  position: relative;

  -webkit-box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1),
  inset 0 -3px 6px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1),
  inset 0 -3px 6px rgba(255, 255, 255, 0.4);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1),
  inset 0 -3px 6px rgba(255, 255, 255, 0.4);
}

.place-map{
  z-index: -1;
  object-fit: cover;
  width: 100%;
}

.place-center{
  background: $light-color;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  -webkit-box-shadow: 0 -3px 5px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: 0 -3px 5px rgba(255, 255, 255, 0.4);
  box-shadow: 0 -3px 5px rgba(255, 255, 255, 0.4);
}

.place-center-title{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  width: 90%;
  max-width: 600px;
  text-align: center;
  background: $light-color;
  padding: 20px;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 36px;
}
.place-categories{
  margin-bottom: 15px;
  padding: 10px;
  overflow-x: auto;
}
.place-category{
  border: 1px solid $dark-color;
  border-radius: 1em;
  padding: 5px 10px;
  margin: 0 5px;
  white-space: nowrap;
}

.place-body{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.place-body-center{
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-items: center;
}
.place-center-subheader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 25px;
}
.place-button-website{
  color: $light-color;
  border: 1px solid #eb0a0a;
  background: radial-gradient(100% 156.45% at 100% 100%, rgba(233, 130, 12, 0.929) 0%, rgba(255, 0, 0, 0) 100%), #eb0a0a;
  transition: background .2s ease-out;
}
.place-button-website:hover{
  color: #eb0a0a;
  background: $light-color;
}
.place-direction {
  color: $light-color;
  border: 1px solid #600aeb;
  background: radial-gradient(100% 156.45% at 100% 100%, #600aeb 0%, rgba(255, 0, 0, 0) 100%), rgba(12, 170, 233, 0.929);
  transition: background .2s ease-out;
}
.place-direction:hover {
  color: #600aeb;
  background: $light-color;
}

.place-clicked-suggestion-box{
  margin: 0px 25px;
  margin-bottom: 100px;
  border-radius: 1em;
  padding: 25px 0;
  white-space: pre-line;
}
.place-image-box{
  width: 500px;
  height: 350px;
  margin: 0 25px;
  display: flex;
  overflow-x: auto;
}
.place-img{
  height: 100%;
  object-fit: contain;
  margin-right: 5px;

  border-radius: 1em;
}

.place-clicked-suggestion {
  margin: 25px;
  text-align: left;
}
.place-clicked-suggestion-title{
  position: relative;
  display: flex;
  align-items: center;
}
.place-clicked-username{
  z-index: 2;
  padding: 0 10px 0 10px;
  background: $light-color;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.place-clicked-username:hover{
  color: #600aeb;
}
.place-clicked-userimage-box{
  z-index: 2;
}
.place-clicked-userimage{
  height: 50px;
  width: 50px;
  border-radius: 1em;
  border: 1px solid white;
  background: white;
}
.place-hr{
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 25px;

  background-image: linear-gradient(to right, $dark-color, rgba(0, 0, 0, 0));
}
.place-clicked-suggestion-description{
  margin: 25px 50px;
}
.place-clicked-suggestion-footer{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
}
.place-clicked-suggestion-footer-icons{
  padding: 0 10px;
  background: $light-color;
  z-index: 2;
}
.place-clicked-suggestion-footer-hr{
  position: absolute;
  top: 25px;
  width: 100%;
  z-index: 1;
}

// Screen
@media all and (max-width: 700px) {
  .place-center-subheader{
    margin: 25px 5px;
  }
  .place-clicked-suggestion-box{
    margin: 50px 10px;
    padding: 15px;
  }
  .place-image-box{
    margin: 0;
    width: 100%;
    height: 250px;
  }
  .place-clicked-suggestion{
    margin: 25px 0;
    padding-bottom: 5px;
  }
  .place-clicked-suggestion-description{
    margin: 25px;
  }
}