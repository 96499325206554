$dark-color: #333335;
$light-color: #f0f6ff;

.footer{
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  
  z-index: 1000;
  background: $dark-color;
  color: $light-color;
  text-align: center;
  padding: 25px 0 50px 0;
  margin-top: 25px;
}
.footer-legal-container{
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.footer-legal:hover{
  text-decoration: underline;
}
.footer-privacy{
  border-right: 1px solid white;
}
.footer-terms{
  border-left: 1px solid white;
}
.footer-legal{
  padding: 0 10px;
}