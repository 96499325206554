// @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,700&family=Montserrat:wght@500&family=Muli:wght@600&display=swap');
// $dark-color: #282c34;
// $dark-color: #383e46;
$dark-color: #333335;
$light-color: #f0f6ff;
$nav-button-height: 7px;

*{
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.med-font{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
}
body{
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 175px;
  position: relative;

  background-color: $light-color;
  color: $dark-color;
}
a {
  background: inherit;
  color: inherit;
  text-decoration: none;
}

button {
  padding: $nav-button-height 20px;
  margin: 0 10px;

  background: inherit;
  color: $dark-color;

  border: none;
  border-radius: 1em;

  cursor: pointer;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $dark-color, rgba(0, 0, 0, 0));
}

.shadow {
 -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2),
  0px -10px 15px rgba(255, 255, 255, 1);
 -moz-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2),
  0px -10px 15px rgba(255, 255, 255, 1);
 box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2),
  0px -10px 15px rgba(255, 255, 255, 1);
}
.shadow-nav-btns {
 -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2),
  0px -5px 10px rgba(255, 255, 255, 1);
 -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2),
  0px -5px 10px rgba(255, 255, 255, 1);
 box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2),
  0px -5px 10px rgba(255, 255, 255, 1);
}
.shadow-bottom {
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}
.shadow-bottom-right {
  -webkit-box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
}

.landing-search-btn, .nav-search-btn {
  color: $light-color;
}

.landing-search-btn:hover, .nav-search-btn:hover {
  background: $light-color;
}

strong {
  font-size: larger;
}

.mr-0{
  margin-right: 0;
}
.p-25{
  padding: 25px;
}
.filters-categories{
  background: $dark-color;
  color: $light-color;
}


// remove default webbrowser styling on search input bar
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type=search]{
  -moz-appearance: none;/* older firefox */
  -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
  appearance: none; /* rest */
}

@media all and (max-width: 700px) {
  *{
    font-size: 14px;
  }
  .med-font{
    font-size: 18px;
  }
  .searchBar-btn{
    padding: 5px 10px;
  }
  header section div .searchBar-btn {
    padding: 5px 20px;
  }
  .landing{
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  .landing-title{
    font-size: 32px;
    letter-spacing: 2px;
  }
  // .landing-search-bar .mapboxgl-ctrl {
  //   width: 100px;
  //   margin: 0 auto;
  // }
  .search-title{
    font-size: 20px;
  }
  .search-subtitle{
    font-size: 16px;
  }
  .mobile{
    height: 50px;
  }
  .search-content{
    margin: 0;
    margin-bottom: 50px;
  }
  .search-header{
    margin: 10px;
  }
  .nav-user-account{
    z-index: 5;
  }
  .login-box{
    width: 90%;
    margin: 25px auto;
  }
  .login-title{
    font-size: 32px;
  }
  .login-hr{
    bottom: 5px;
  }
}