$height: 7px;

.mapboxgl-ctrl-geocoder--input{
  border-bottom-left-radius: 1em;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  padding-left: 25px;
  border-bottom: 1px solid white;
  transition: border-bottom .5s ease-in;
}
.mapboxgl-ctrl{
  width: 400px;
  max-width: 100%;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact{
  min-height: 0;
}
.mapboxgl-ctrl-geocoder--button {
  background: white !important;
}
.mapboxgl-ctrl-geocoder--icon-search{
  display: none;
}


.searchBar {
  display: flex;
  border-radius: 1em;
  justify-content: center;
  width: fit-content;
  margin: auto;
}
// #geocoder{
//   // padding: $height 200px $height 20px;
//   border-bottom-left-radius: 1em;
//   border-top-left-radius: 1em;
//   border: none;
// }
.searchBar-dropdown{
  display: none;
  position: absolute;
  background: white;
  padding-top: $height;
  padding-bottom: $height;
  padding-left: 20px;
  width: 386px;

  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
#geocoder:focus + .searchBar-dropdown {
  display: flex;
  cursor: pointer;
}
.searchBar-dropdown:hover{
  display: flex;
  cursor: pointer;
}

.searchBar-btn {
  order: 3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  color: white;
  background: radial-gradient(100% 156.45% at 100% 100%, #00D1FF 0%, rgba(0, 212, 255, 0.928557) 0%, rgba(0, 212, 255, 0) 100%), #00A3FF;
  border: 1px solid rgba(0, 212, 255, 0.928557);
  transition: background .2s ease;
  height: 100%;
}

.searchBar-btn:hover {
  // border: 1px solid rgba(0, 212, 255, 0.928557);
  color: #00A3FF;
  background: white;
}

.searchBar-btn:focus {
  outline: none;
}

.searchBar-btn-transition:active {
  transform: scale(.75);
}

.mapboxgl-ctrl-geocoder--input:focus,
.nav-btn:focus {
  border: none;
  outline: none;
}

.mapboxgl-ctrl-geocoder--input:focus {
  border-bottom: 1px solid rgba(0, 212, 255, 0.928557);
}

@media all and (max-width: 1200px){
  .mapboxgl-ctrl{
    width: 300px;
  }
}
@media all and (max-width: 1100px){
  .mapboxgl-ctrl{
    width: 400px;
  }
}
@media all and (max-width: 700px){
  .mapboxgl-ctrl{
    width: 300px;
  }
}
@media all and (max-width: 500px){
  .mapboxgl-ctrl{
    width: 100%;
  }
}