$dark-color: #333335;
$light-color: #f0f6ff;

.map-box{
  position: relative;
}
.map-search-button{
  z-index: 1;
  position: sticky;
  top: 10px;
  right: 10px;
  float: right;
  margin: 0;
  padding: 10px 20px 10px 20px;

  text-align: center;
  color: white;
  background: radial-gradient(100% 126.45% at 100% 100%, #ffe600 0%, rgba(255, 196, 0, 0.929) 0%, rgba(0, 212, 255, 0) 100%), #ff7b00;
  background: radial-gradient(100% 156.45% at 100% 100%, #ff0000 0%, rgba(255, 123, 0, 0.929) 0%, rgba(255, 0, 0, 0) 100%), #ff8800;
  border: 1px solid #ff7b00;
  transition: background .2s ease;
}
.map-search-button:hover{
  color: #ff7b00;
  background: rgb(255, 255, 255);
}
.map-search-button:focus {
  outline: none;
}

.map-popup{
  // height: 150px;
  width: 150px;
  background: $dark-color;
  border-radius: 1em;
}
// .map-popup-box{
//   background: $dark-color;
//   color: white;

//   border-radius: 1em;
// }
.map-popup-img{
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
} 
.map-popup-title{
  margin: 0;
  padding: 5px;
}
/* Marker tweaks */
.mapboxgl-marker {
  cursor: pointer !important;
}
.mapboxgl-popup-content{
  margin: 0;
  padding: 0;
  border-radius: 1em;
  cursor: pointer;
}
// .mapboxgl-popup-tip{
//   color: $dark-color;
// }

// Screen
@media all and (max-width: 1100px) {
  .map-search-button{
    z-index: 1;
    top: 192px;
  }
}

@media all and (max-width: 700px) {
  .mapboxgl-ctrl-geocoder--input{
    font-size: 16px;
    height: 35px;
  }
  .mapboxgl-ctrl-geocoder--icon{
    margin-top: 0;
  }
  .map-search-button {
    top: 192px;
    padding: 5px 10px;
    border-radius: 10px;
  }
}

@media all and (max-width: 350px) {
  .geocoder {
    background: white;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    padding-top: 5px;
  }
}