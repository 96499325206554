$dark-color: #333335;
$light-color: #f0f6ff;

.post{
  border-radius: 1em;
  margin: 50px auto 100px auto !important;
  padding-top: 25px;
}
.post-box{
  display: flex;
  margin: 25px auto;
  // padding: 25px 0  50px 0;
  justify-content: space-between;
}
.post-step-title{
  margin: 50px auto 25px auto;
  text-align: center;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
}
.post-steps{
  display: flex;
  justify-content: space-evenly;
}
#post-step{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;
}
#post-step-1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;

  border-radius: 50%;
  padding: 0 10px;
  transition: all .2s ease;
}
#post-step-2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;

  border-radius: 50%;
  padding: 0 10px;
  transition: all .2s ease;
}
#post-step-3{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;

  border-radius: 50%;
  padding: 0 10px;
  transition: all .2s ease;
}
.post-step-active{
  color: $light-color;
  background: #00A3FF;
  transform: scale(1.5);
}
.post-form{
  width: 100%;
  text-align: center;
}
.post-form-left-side{
  width: 300px;
}

.post-form-right-side{
  position: relative;
  width: 600px;
}
.post-form-content{
  margin: 25px;
  margin-top: 50px;
}
.post-form-btns{
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.post-form-previous-btn{
  background: $light-color;
  color: $dark-color;
  border: 1px solid $dark-color;
  padding: 10px 25px;
}
.post-form-next-btn{
  background: $dark-color;
  color: $light-color;
  border: 1px solid $dark-color;
  padding: 10px 25px;
}
.post-title{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
}
.post-form-hr{
  width: 75%;
  margin: 0 auto;
}
.post-form-steps{
  margin-top: 50px;
  padding: 50px 100px;
  text-align: center;
}
.post-form-step-header{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;

  text-align: left;
}
.post-form-step-number{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;

  color: $light-color;
  background: $dark-color;
  border-radius: 50%;
  padding: 0 10px;
}
.post-form-step-description{
  font-size: 18px;
  margin-left: 25px;
}

.post-form-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}
.post-form-images-order-2{
  order: 1;
}
.post-form-images-order-1{
  order: 2;
}
.post-form-images{
  width: 100%;
  max-width: 450px;
  height: 250px;
  border-radius: 1em;
  display: flex;
  overflow: auto;
  background: white;

  border: 1px dashed $dark-color;
  overflow-y: hidden;
}
#file{
  display: none;
}
.post-file-button{
  color: $light-color;
  background: #126486;
  border-radius: 1em;
  padding: 10px 20px;
  
  cursor: pointer;
}
.post-image{
  object-fit: contain;
  height: 100%;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.post-image:hover{
  background: black;
  opacity: .5;

  cursor: pointer;
}
.overlay{
  margin-right: 5px;
  background: black;
  height: 250px;
}
.post-form-place-content{
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}
#auto-suggest > .mapboxgl-ctrl-geocoder{
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  margin: 10px auto;
  z-index: 5;
  width: 100%;
  transition: border-bottom .5s ease-in;
}
#auto-suggest > div > .mapboxgl-ctrl-geocoder--input {
  border-bottom-right-radius: 1em;
}
#address > .mapboxgl-ctrl-geocoder{
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  margin: 10px auto;

  width: 100%;
  transition: border-bottom .5s ease-in;
}
#address > div > .mapboxgl-ctrl-geocoder--input {
  border-bottom-right-radius: 1em;
}

.post-form-place {
  float: left;
  border-radius: 1em;
  border: none;
  width: 60%;
  margin: 10px auto;
  padding: 8px 0 8px 25px;
  border-bottom: 1px solid white;
  font-size: 16px;
  color: $dark-color;
}
.post-form-place:focus{
  outline: none;
}
.post-form-place:focus{
  border-bottom: 1px solid #126486;
  transition: border-bottom .5s ease-in;
}

.post-form-auto-suggest-label{
  text-align: left;
  position: absolute;
  top: -10px;
  left: 10px;
}
.post-form-auto-suggest-label-website {
  text-align: left;
  position: absolute;
  top: -20px;
  left: 10px;
}
.text-right{
  text-align: right;
  padding-right: 50px;
}
.fa-arrow-alt-circle-right{
  font-size: 16px;
}
.post-form-or{
  background: $dark-color;
  color: $light-color;
  width: 40px;
  padding: 10px 8px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
}
.post-form-section-center {
  // justify-content: center !important;
  flex-direction: column;
}
.post-form-website{
  display: flex;
  margin: 50px 10px 75px 10px;
  width: 75%;
  position: relative;
}
.post-form-website-name{
  white-space: nowrap;
  padding: 10px;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  color: $light-color;
  border: 1px solid #eb0a0a;
  background: radial-gradient(100% 156.45% at 100% 100%, rgba(233, 130, 12, 0.929) 0%, rgba(255, 0, 0, 0) 100%), #eb0a0a;
}
.post-form-website-edit{
  background: white;
  width: 100%;
  // border-radius: 1em; 
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  border: 1px solid white;
  padding-left: 15px;
}
.post-form-website-edit:focus{
  border: 1px solid #eb0a0a;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  outline: none;
}
.post-form-website-invalid{
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: red;
  display: none;
}
.post-form-textarea{
  resize: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 1em;
  padding: 10px;
  border: 1px solid white;
  height: 300px;
}
.post-form-textarea:focus{
  outline: none;
  border: 1px solid #126486;

  transition: border .2s ease-out;
}
.post-form-preview-btn{
  border: 1px solid #126486;
  background: radial-gradient(100% 200% at 50% 50%, #2C5364 0%, rgba(44, 83, 100, 0.9) 0%, rgba(44, 83, 100, 0.2) 100%), #0F2027;
  color: $light-color;
  padding: 10px 25px;
  transition: background .2s ease;
}
.post-form-preview-btn:hover{
  color: #126486;
  background: white;
}
.post-form-preview-btn:focus{
  outline: none;
}

.post{
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.post-preview-box{
  display: flex;
  margin: 50px auto;
  padding: 25px 0 50px 0;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1em;
}
.post-preview-website{
  margin: 50px;
  display: flex;
  justify-content: center;
}
.post-preview-button-website{
  color: $light-color;
  border: 1px solid #eb0a0a;
  background: radial-gradient(100% 156.45% at 100% 100%, rgba(233, 130, 12, 0.929) 0%, rgba(255, 0, 0, 0) 100%), #eb0a0a;
  transition: background .2s ease-out;
}
.post-preview-button-website:hover{
  color: #eb0a0a;
  background: $light-color;
}
.post-preview-image-box{
  margin: 50px 25px;
  
  display: flex;
  justify-content: center;
}
.post-preview-images{
  width: 90%;
  max-width: 450px;
  height: 250px;
  overflow-x: auto;
  border-radius: 1em;
  display: flex;
}
.post-preview-image{
  border-radius: 1em;
}

.post-preview-content{
  margin: 50px 25px;

  display: flex;
  justify-content: center;
}
.post-preview-suggestion{
  width: 85%;
  max-width: 400px;
}
.post-preview-suggestion-title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  
  text-align: center;
  margin: 10px;
}
.post-preview-suggestion-box{
  border-radius: 1em;
  border: 1px solid $dark-color;
  padding: 25px;
  white-space: pre-line;
}
.fa-exclamation{
  padding-right: 5px;
}
.post-preview-warning{
  background: rgba(255, 255, 0, 0.5);
  margin: 100px auto 50px auto;
  padding: 25px;
  border-radius: 1em;
  width: 90%;
  max-width: 500px;
  text-align: center;
}
.post-preview-image{
  height: 100%;
  margin-right: 5px;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.post-preview-title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 36px;
  margin: 10px auto;
}
.post-preview-body{
  margin: 10px;
}
.post-preview-categories{
  width: fit-content;
  max-width: 100%;
  padding: 10px;
  overflow-x: auto;
  margin: 0 auto;
  margin-bottom: 15px;
}
.post-preview-category{
  border: 1px solid $dark-color;
  border-radius: 1em;
  padding: 5px 10px;
  margin: 0 5px;
  white-space: nowrap;
}
.post-preview-description{
  margin: 10px auto;
}
.post-preview-location{
  margin: 25px auto;
  width: 100%;
  height: 300px;
  position: relative;
}
.post-preview-place{
  background: $light-color;
  color: $dark-color;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 75%;
  max-width: 500px;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}
.post-preview-place-map{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.post-preview-btns{
  margin: 10px auto;
  margin-top: 50px;
}
.post-preview-btn-dark{
  border: 1px solid #126486;
  background: radial-gradient(100% 200% at 50% 50%, #2C5364 0%, rgba(44, 83, 100, 0.9) 0%, rgba(44, 83, 100, 0.2) 100%), #0F2027;
  transition: background .2s ease;
  color: $light-color;
  margin: 0 25px;
}
.post-preview-btn-dark:hover{
  background: $light-color;
  color: #126486;
}
.post-preview-btn-light{
  border: 1px solid $dark-color;
  transition: background .2s ease;
  margin: 0 25px;
}
.post-preview-btn-light:hover{
  background: $dark-color;
  color: $light-color;
}
.post-preview-loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.post-preview-loading-meter {
  height: 25px;
  width: 250px;
  position: relative;
  background: white;
  border-radius: 1em;
  border: 2px solid $dark-color;
  padding: 10px;
  margin-top: 50px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.post-preview-loading-meter-progress {
  width: 0%;
  height: 100%;
  border-radius: 1em;
  background: green;
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  left: 0;
  transition: width ease 1s;
}

.post-confirmed{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
  max-width: 800px;
  margin: 50px auto;
  border-radius: 1em;
}
.checkmark{
  font-size: 72px;
  padding: 50px 0 10px 0;
  color: darkgreen;
}
.post-confirmed-title{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
}
.post-confirmed-hr{
  width: 50%;
  margin-bottom: 25px;
}
.post-confirmed-body{
  padding: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
}
.post-confirmed-breaks{
  display: block;
  margin: 10px 0;
  content: '';
}
.post-confirmed-btn{
  background: $dark-color;
  color: $light-color;

  margin: 50px auto 25px auto;
}

@media all and (max-width: 930px) {
  .post-box{
    margin-top: 25px;
  }
  .post-title{
    font-size: 32px;
  }
  .post-form-steps{
    padding: 10px;
    width: 100%;
  }
  .post-form-section{
    margin: 10px 20px;
    flex-direction: column;
  }
  .post-form-content{
    margin: 20px 15px;
  }
  .post-form-steps{
    padding: 20px;
  }
  .post-form-step-header{
    font-size: 16px;
    padding-bottom: 10px;
  }
  .post-form-step-number{
    font-size: 16px;
    padding: 0 7px;
  }
  .post-form-step-description{
    font-size: 14px;
  }
  .fa-arrow-alt-circle-right{
    transform: rotate(90deg);
  }
  .text-right{
    margin-top: 10px;
    padding: 0;
    text-align: center;
  }
  .post-form-images-order-2 {
    order: 2;
  }
  .post-form-images-order-1 {
    order: 1;
    margin-bottom: 25px;
  }
  .post-form-images{
    margin: 0 auto 10px auto;
  }
  .post-file-button{
    padding: 10px;
  }
  .left-side-none{
    display: none;
  }
  .post-form-left-side{
    width: 100%;
    margin-bottom: 20px;
  }
  .post-form-right-side, .post-form-website{
    width: 100%;
  }
  .post-form-place{
    width: 75%;
  }
  .post-preview-image-box{
    width: 100%;
    margin: 10px auto;
  }
  .post-preview-title{
    font-size: 20px;
  }
  .post-preview-location{
    margin: 10px auto;
    width: 100%;
  }
  .post-preview-place-map{
    height: 100%;
    object-fit: cover;
  }
  .post-preview-categories{
    // justify-content: start;
  }
  .post-preview-content{
    margin: 25px;
  }
  .post-preview-warning{
    margin: 75px auto;
  }
  .post-preview-btns{
    margin-top: 25px;
    margin-bottom: 0;
  }
}