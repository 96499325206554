$height: 10px;
$border-thickness: 2px;
$dark-color: #333335;
$light-color: #f0f6ff;

.landing{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 20vh;
  padding-bottom: 25vh;
  border-bottom-left-radius: 5em;
  border-bottom-right-radius: 5em;

  // background-image: url('https://fun-stuff-2-do-bucket.s3.us-east-2.amazonaws.com/Fun+Stuff+2+Do/gracia-dharma-large.jpg');
  // background-size: cover;
  // background-position: right center;
  background: #2980B9;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #6DD5FA, #2980B9);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #6DD5FA, #2980B9);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  z-index: 0;
  overflow: hidden;
}
.landing-content{
  padding: 25px;
  margin: auto;
  border-radius: 1em;
  
  background: rgba(240, 246, 255, 0.75);
  -webkit-box-shadow: 0px 0px 50px 0px $light-color;
  -moz-box-shadow: 0px 0px 50px 0px $light-color;
  box-shadow: 0px 0px 50px 0px $light-color;
}
.landing-title{
  font-family: Chonburi;
  font-size: 64px;
  letter-spacing: 5px;
  margin-bottom: 25px;
  text-align: center;
}

.landing-searchBar{
  transform: scale(1.25);
}

.landing-categories{
  margin-top: 25px;
  width: 90vw;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.landing-category{
  padding: 10px;
  transition: transform .2s;
}
.landing-category:hover, .landing-category-active{
  cursor: pointer;
  color: #CC00FF;
}
.landing-category-active{
  transform: scale(1.2);
}
.landing-photo-credit{
  position: absolute;
  bottom: 25px;
  left: 25px;
  color: white;
}
.landing-photo-credit-links{
  padding: 0 5px;
}
.landing-photo-credit-links:hover{
  text-decoration: underline;
}
.landing-balloon-horizontal-movement{
  position: absolute;
  top: 0;
  right: -150px;
  width: 100px;
  animation: leftRight 250s infinite linear;
  z-index: -2;
}
.landing-balloon-image{
  position: absolute;
  z-index: -3;
  top: 50px;
  right: 0px;
  width: 150px;
  animation: upDown 50s alternate infinite ease-in-out;
}
.landing-mountain-image{
  z-index: -2;
  position: absolute;
  bottom: -175px;
  right: 0;
  width: 1250px;
  transition: all .02s linear;
}
@keyframes upDown{
  to {transform: translateY(100px);}
}
@keyframes leftRight{
  to {transform: translateX(calc(-100vw - 150px));}
}
@media all and (max-width: 700px){
  .landing-mountain-image{
    bottom: -100px;
    right: 0;
    width: 750px;
  }
  .landing-content{
    padding: 25px 0;
  }
  .landing-balloon-image{
    width: 100px;
    top: 25px;
    right: 50px;
  }
  @keyframes upDown{
    to {transform: translateY(75px);}
  }
  @keyframes leftRight{
    to {transform: translateX(calc(-100vw - 100px));}
  }
}