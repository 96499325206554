.login{
  height: 100vh;
}
.login-FB{
  color: white;
  background: #1877F2;
  border-radius: 2em;
  margin: 0;
  margin-top: 50px;
  padding: 0;
  height: 40px;
  width: 247px;

  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;
}
.login-F-logo{
  height: 25px;
  padding: 0 10px;
  object-fit: contain;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.login-G {
  color: gray;
  background: white;
  border-radius: 2em;
  border: 1px solid gray;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  height: 40px;
  width: 247px;

  font-family: sans-serif;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;
}
.login-G-logo{
  height: 21px;
  padding: 0 12px;
  object-fit: contain;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.login-box{
  padding: 25px 0 50px 0;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  border-radius: 1em;
}
.login-title{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;

  text-align: center;
  // padding-bottom: 50px;
  position: relative;
  width: 100%;
}
.login-hr{
  position: absolute;
  bottom: 10px;
  margin: 0 auto;
  width: 75%;
  left: 50%;
  transform: translateX(-50%);
}
.login-hr-splitter{
  margin: 50px 50px 10px 50px;
  width: 75%;
}
.login-privacy-terms{
  text-align: center;
}
.login-privacy-terms-links{
  color: #00A3FF;
}
.login-privacy-terms-links:hover{
  text-decoration: underline;
}
