.search{
  margin-top: 25px;
  text-align: center;
}
.search-top{
  flex-direction: column;
  margin-bottom: 5px;
}
.search-title{
  font-family: Chonburi;
  letter-spacing: 1px;
  font-size: 36px;
}
.search-subtitle{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 24px;
}
.search-body{
  display: flex;
}
.search-filters{
  width: 50px;
}

.search-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;
  width: 95%;
  position: relative;
}

.search-sort-btn {
  margin: 0;
  color: white;
  background: radial-gradient(100% 156.45% at 100% 100%, #00D1FF 0%, rgba(0, 212, 255, 0.928557) 0%, rgba(0, 212, 255, 0) 100%), #00A3FF;
  border: 1px solid rgba(0, 212, 255, 0.928557);
  transition: background .2s ease;
}
.search-sort-btn:hover {
  color: #00A3FF;
  background: white;
}
.search-sort-btn:focus{
  outline: none;
}
.search-sort-box{
  position: relative;
}
.search-sort-styles {
  display: none;
  z-index: 1;
  position: absolute;
  top: 34px;
  right: 0;
  // left: 0;
  width: 125px;
  padding: 10px;
  background: white;
  border-radius: 1em;
}

.search-sort-style {
  padding: 5px;
  cursor: pointer;
}

.search-content{
  width: 100%;
  margin: 0 25px 0 25px;
  justify-content: center;
  align-items: flex-start;
  
  min-height: calc(100vh - 95px);
}
.search-cards{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.search-map-box {
  width: 33vw;
  max-width: 500px;
  height: calc(100vh - 125px);
  position:-webkit-sticky;
  position: sticky;
  top: 125px;
}
.search-map{
  width: 33vw;
  max-width: 500px;
  height: calc(100vh - 125px);
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.mobile{
  display: none;
}
.search-no-results{
  background: rgba(255, 255, 0, 0.5);
  border-radius: 1em;
  padding: 25px;
  width: 75%;
  margin: 0 auto;
}
.search-no-results-example{
  text-decoration: underline;
  font-size: 16px;
  padding: 0 5px;
  cursor: pointer;
}

.search-content-loading{
  margin-top: 250px;
  display: flex;
  justify-content: center;
  width: 100%;
}
// Screen
@media all and (max-width: 1100px) {
  .mobile{
    display: block;
    height: 75px;
  }
  .website{
    display: none;
  }
  .search-filters-overlay{
    position: absolute;
    z-index: 1;
  }
  .search-body{
    flex-direction: column;
  }
  .search-map-box{
    order: 1;
    position: relative;
    width: 90%;
    max-width: 750px;
    margin: 0 auto;
    top: 0;
    height: 100%;
  }
  .search-map{
    margin: 0 auto;
    border-radius: 1em;
    width: 100%;
    max-width: 750px;
    height: 40vh;
    max-height: 500px;
    margin-bottom: 25px;
  }
  .map-search-button{
    // z-index: -1 !important;
    position: absolute !important; 
    top: 10px !important;
    right: 10px !important;
  }
  .search-content{
    order: 2;
    margin: 0;
    margin-bottom: 50px;
  }
}


@media all and (max-width: 700px){
  .search-sort-btn{
    padding: 10px;
  }
  .search-result-number:before{
    content: "\a";
    white-space: pre;
  }
  .search-sort-styles {
    top: 40px;
  }
}