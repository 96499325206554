$dark-color: #333335;
$light-color: #f0f6ff;

.card {
  width: 200px;
  margin: 14px;
  border-radius: 1em;
  transition: transform .2s ease-out;
  border: 1px solid white;
}
.card-img-box{
  z-index: 0;
  position: relative;
  width: 100%;
  height: 200px;
}
.card-img {
  min-width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  border-radius: 1em;
  cursor: pointer;
}
.carousel{
  display: flex;

  overflow-x: hidden;
  -webkit-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
}
.carousel > img {
  scroll-snap-align: start;
}
.card-userImg {
  z-index: 5;
  width: 40px;
  height: 40px;
  object-fit: contain;
  // border: 1px solid $dark-color;
  border-radius: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid white;
  background: white;
}

.card-img-arrows {
  z-index: 5;
  cursor: pointer;
  background: $light-color;
  color: $dark-color;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  left: 0;
  right: 0;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  opacity: .5;
}
.card-img-arrows:hover{
  opacity: 1;
}
.card-img-arrow-left{
  padding: 5px 15px;
  margin: 0 10px;
  margin-left: 0;
}
.card-img-arrow-right{
  padding: 5px 15px;
  margin: 0 10px;
  margin-right: 0;
}

.card-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  height: 47px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // padding: 0 15px 0 15px;
  margin: 15px 15px 0 15px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.card-body {
  height: 70px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 15px 15px 0 15px;
}

.card-footer {
  // height: 40px;
  margin: 15px 15px 0 15px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid white;
}
.card-footer-delete{
  // height: 50px;
  margin: 15px 15px 0 15px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid white;
}

.card-body-buttons{
  display: flex;
  justify-content: space-between;
}

.card-body-button{
  width: 100%;
  margin: 25px 5px 0 5px;
  padding: 10px;

  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1),
  0 -10px 10px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1),
  0 -10px 10px rgba(255, 255, 255, 0.4);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1),
  0 -10px 10px rgba(255, 255, 255, 0.4);
}

.card-body-button-locate{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;

  background: $dark-color;
  color: $light-color;
}
.card-body-button-readmore{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 1px;

  background: $dark-color;
  color: $light-color;
}
.card-body-button-edit{
  background: $dark-color;
  color: $light-color;
}
.fa-thumbs-up, .fa-star, .fa-trash{
  cursor: pointer;
}
.fa-thumbs-up:hover{
  color: rgb(0, 162, 216);
}
.fa-star:hover{
  color: rgb(218, 218, 0);
}
.like-active{
  color: rgb(0, 162, 216);
}
.star-active{
  color: rgb(218, 218, 0);
}

@media all and (max-width: 700px) {
  .card{
    margin: 12px 6px 12px 6px;
    width: 175px;
  }
  .card-img{
    min-width: 100%;
  }
  .card-body{
    height: 70px;
  }
  .card-body-button{
    padding: 10px 0;
  }
  .card-img-arrows{
    width: 90px;
  }
  .card-img-arrow-left{
    margin-right: 5px;
  }
  .card-img-arrow-right{
    margin-left: 5px;
  }
}