$dark-color: #333335;
$light-color: #f0f6ff;

.about{
  margin-top: 50px;
  padding: 25px;
  padding-bottom: 10px;
  overflow-x: hidden;
}
.about-row-1{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.about-row-2{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.about-row-3{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.about-title{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;

  width: fit-content;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  padding: 0 5px;
  
  background: rgba(240, 246, 255, 0.75);
  -webkit-box-shadow: 0px 0px 50px 0px $light-color;
  -moz-box-shadow: 0px 0px 50px 0px $light-color;
  box-shadow: 0px 0px 50px 0px $light-color;
}
.about-title-box{
  margin-bottom: 100px;
  max-width: 500px;
  position: relative;
}
.about-text-box{
  width: 80vw;
  max-width: 400px;
  margin: auto;
  
  border-radius: 10px;
  padding: 0 5px;

  background: rgba(240, 246, 255, 0.75);
  -webkit-box-shadow: 0px 0px 50px 0px $light-color;
  -moz-box-shadow: 0px 0px 50px 0px $light-color;
  box-shadow: 0px 0px 50px 0px $light-color;
}
.about-text{
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-search{
  z-index: -1;
  position: absolute;
  top: -25px;
  right: -100px;
  width: 250px;
}
.about-road {
  z-index: -1;
  position: absolute;
  top: -25px;
  right: -100px;
  width: 250px;
}
.about-map{
  z-index: -1;
  position: absolute;
  top: -100px;
  left: -100px;
  width: 300px;
}
.about-globe{
  z-index: -1;
  position: absolute;
  top: -50px;
  left: -100px;
  width: 250px;
}
a:hover{
  text-decoration: underline;
}
.about-icon-credit{
  margin-top: 25px;
}

@media all and (max-width: 700px) {
  .about-title{
    font-size: 24px;
  }
  .about-search {
    width: 200px;
    right: -50px;
  }
  .about-map {
    width: 200px;
    left: -75px;
    top: -50px;
  }
  .about-road{
    width: 200px;
    right: -75px
  }
  .about-globe{
    width: 200px;
    left: -75px;
    top: -25px
  }
}