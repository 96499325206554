$padding-left: 25px;
$move-left: -180px;
$dark-color: #333335;
$light-color: #f0f6ff;

.filters{
  // position: sticky;
  // top: 125px;
  margin-top: 25px;
  text-align: left;
  transition: transform .25s ease-out;
}
.filters-move-left{
  transform: translateX($move-left);
}

.filters-title-bar{
  z-index: 2;
  color: white;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  background: radial-gradient(196.35% 4936.4% at 200.28% 100%, #CC00FF 0%, rgba(255, 255, 255, 0) 100%), #74009C;
  border: 1px solid #CC00FF;
  padding: 5px 15px 5px $padding-left;

  display: flex;
  justify-content: space-between;

  cursor: pointer;
  width: 225px;
  transition-property: background, color, transform;
  transition-duration: .2s;
  transition-timing-function: ease;
}
.filters-title-bar:hover{
  background: white;
  color: #74009C;
}

.filters-title-bar > * {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
}
.filters-arrow > *{
  font-size: 20px;
  transition: transform 1s ease-in;
}
.filter-arrow-rotate{
  transform: scale(-1);
}

.filters-categories{
  padding: 10px 5px 10px $padding-left + 10px;
  width: 175px;
  border-bottom-right-radius: 1em;

  cursor: pointer;
}

.filters-main-category{
  padding-bottom: 5px;
}
.filters-sub-category{
  padding-bottom: 5px;
  padding-left: 10px;
}
.filters-sub-categories {
  margin-bottom: 10px;
}
.filters-main-category:hover, 
.filters-sub-category:hover, 
.category-active{
  color: #CC00FF;
}
.filters-clear-btn{
  background: $light-color;
  margin-top: 25px;
}

.mapboxgl-ctrl-bottom-left {
  z-index: 0;
}
// Screen
@media all and (max-width: 1100px) {
  .filters{
    z-index: 1;
    top: 182px;
    margin-top: 0;
  }
}