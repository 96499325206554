.following{
  margin: 50px auto;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 90%;
  max-width: 500px;

  border-radius: 1em;
}
.following-title{
  width: 100%;
  text-align: center;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
}
.following-hr{
  width: 75%;
  margin: auto;
}
.following-content{
  margin: 50px 100px;
}
.following-content-followee{
  display: flex;
  align-items: center;
  border-radius: 1em;
  border: 1px solid white;
}
.following-content-followee:hover{
  border: 1px solid #ff0497;
}
.following-content-followee-image{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  border-right: 1px solid white;
}
.following-content-followee-name{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;

  margin-left: 25px;
}
.following-content-none{
  text-align: center;
  background: rgba(255, 255, 0, 0.5);
  padding: 25px;
  border-radius: 1em;
}
.following-content-loading{
  margin: 50px;
  text-align: center;
}

@media all and (max-width: 700px){
  .following-content{
    margin: 50px 25px;
  }
}