$dark-color: #333335;
$light-color: #f0f6ff;

.account-header{
  display: flex;
  align-items: center;
  margin-top: 75px;
}
.account-detail-box{
  color: $light-color;
  background: $dark-color;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}
.account-detail-list{
  padding: 25px 50px;
}
.account-detail-list:hover{
  cursor: pointer;
}
.account-detail-header{
  display: flex;
  padding-left: 10vw;
}
.account-userImg{
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
}
.account-userName-box{
  margin-left: 50px;
  margin-top: 50px;
  letter-spacing: 2px;
}
.account-userName{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
}
.account-public-profile-btn{
  margin-top: 15px;
  margin-left: 0;
  color: white;
  background: radial-gradient(100% 156.45% at 100% 100%, #00D1FF 0%, rgba(0, 212, 255, 0.928557) 0%, rgba(0, 212, 255, 0) 100%), #00A3FF;
  border: 1px solid rgba(0, 212, 255, 0.928557);
  transition: background .2s ease;
}
.account-public-profile-btn:hover{
  color: #00A3FF;
  background: white;
}

.account-details{
  width: 650px;
  border-radius: 1em;
  margin: 50px auto;
}
.account-details-header{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  padding-top: 25px;
}
.account-details-content{
  margin: 25px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#usernameError{
  color: red;
}
.account-details-left-side{
  text-align: end;
  width: 150px;
}
.account-details-right-side{
  width: 325px;
  background: white;
  color: $dark-color;
  padding: 10px 25px;
  border-radius: 1em;
  height: 38px;
  white-space: nowrap;
}
.account-details-no-border{
  background: none;
  padding: 0;
  margin: 0;
}
.account-img-box{
  background: none;
  height: 150px;
  padding: 0;
}
.account-img{
  object-fit: cover;
  height: 150px;
  width: 150px;
  border-radius: 1em;
}
.account-img-editing{
  border: 1px dashed black;
}
.account-detail-btn-box{
  display: flex;
  justify-content: space-evenly;
}
.account-details-edit-btn{
  margin: 50px auto;
  width: 100px;
  background: $dark-color;
  color: $light-color;
}
.account-details-cancelSave{
  justify-content: space-evenly;
  margin: 50px auto;
} 
.account-details-save-btn{
  background: $dark-color;
  color: $light-color;
}

.account-detail-img-btn{
  margin-top: 10px;
  color: $light-color;
  background: #126486;
  border-radius: 1em;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
}

.account-edit-posts{
  margin: 75px 25px;
}
.account-posts-results{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}
.account-details-loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}

// Delete Account page
.delete{
  width: 650px;
  border-radius: 1em;
  margin: 50px auto;
}
.delete-header{
  font-family: 'Cormorant Garamond',
  serif;
  font-weight: 700;
  font-size: 48px;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  padding-top: 25px;
}
.delete-content{
  margin: 25px 100px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  text-align: center;
}
.delete-content-option{
  margin: auto;
}
.delete-content-option-warning{
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
#delete-content-bullet-points-color{
  margin-bottom: 25px;
}
#delete-content-bullet-points-color li::before {
  content: "\2022";
  color: #ff1e00;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.delete-content-option-warning-header {
  padding: 0 0 10px 0;
}
.delete-content-option-warning-list{
  padding-left: 50px;
}
.delete-uppercase{
  text-transform: uppercase;
  color: #ff1e00;
}
.delete-btn-continue{
  margin: 25px 0;
  color: white;
  background: radial-gradient(100% 156.45% at 100% 100%, #ff6600 0%, rgba(255, 145, 0, 0.929) 0%, rgba(0, 212, 255, 0) 100%),
  #ff1e00;
  border: 1px solid #ff1e00;
  transition: background .2s ease;
}
.delete-btn-continue:hover{
  color: #ff1e00;
  background: white;
}

.delete-btn-cancel{
  margin: 25px 10px;
  color: white;
  background: $dark-color;
  border: 1px solid $dark-color;
  transition: background .2s ease;
}
.delete-btn-cancel:hover{
  color: $dark-color;
  background: white;
}
.delete-btn-delete{
  margin: 25px 10px;
  color: white;
  background: radial-gradient(100% 156.45% at 100% 100%, #ff7300 0%, rgba(255, 102, 0, 0.929) 0%, rgba(0, 212, 255, 0) 100%), #ff1e00;
  border: 1px solid #ff1e00;
  transition: background .2s ease;
}
.delete-btn-delete:hover{
  color: #ff1e00;
  background: white;
}
@media all and (max-width: 1000px) {
  .account-header {
    margin-top: 50px;
  }
  .account-detail-header {
   padding: 0 25px;
  }
}
@media all and (max-width: 700px) {
  .account-header{
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
  .account-right-side{
    order: 1;
    margin: 50px 20px;
  }
  .account-detail-header{
    padding: 0;
  }
  .account-userImg{
    height: 150px;
    width: 150px;
  }
  .account-userName-box{
    margin: 10px;
  }
  .account-userName{
    font-size: 24px;
    text-align: center;
  }
  .account-public-profile-btn{
    margin: 0;
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
  }
  .account-left-side {
    order: 2;
    margin-right: auto;
    margin-top: 15px;
  }
  .account-details, .delete{
    width: 90%;
    margin: 75px auto;
  }
  .account-details-content {
    flex-direction: column;
    margin: 25px;
    text-align: center;
  }
  .account-details-left-side{
    text-align: center;
  }
  .account-details-left-side{
    margin: 5px 0;
  }
  .account-details-right-side, .account-img-box{
    width: 100%;
  }
  .account-edit-posts{
    margin: 75px 0;
  }
  .delete-content{
    margin-left: 50px;
    margin-right: 50px;
  }
  .delete-content-option-warning-list {
    padding-left: 25px;
  }
}