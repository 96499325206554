.legal{
  margin: 50px auto;
  border-radius: 1em;
  padding: 50px 75px;
  width: 90%;
  max-width: 750px;
}
.legal-title{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-size: 48px;
}
.legal-hr{
  width: 100%;
  max-width: 500px;
  margin-bottom: 50px;
}
.legal-content{
  margin-left: 20px;
}
.legal-content-header{
  margin: 50px 0 10px 0;
}
.legal-content-paragraph{
  margin: 10px 0;
}
.legal-highlight{
  color: #00A3FF;
}
ul{
  list-style: none;
}
#bullet-points-color li::before{
  content: "\2022";
  color: #00A3FF;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
#contact-email:hover{
  text-decoration: underline;
}
@media all and (max-width: 700px) {
  .legal{
    padding: 50px 25px;
  }
}